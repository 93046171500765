<template>
    <v-row v-show="profile && !loading && dashboardViews.length > 0">
        <v-col cols="10">
            <v-card v-if="view">
                <v-card-text>
                    <issues-groups
                        v-if="displayGroup"
                        :view.sync="view"
                        :filters.sync="filters"
                        v-on:update-group="updateGroup"
                    />
                    <issues-view
                        :view.sync="view"
                        :filters.sync="filters"
                        :group.sync="group"
                        v-on:update-selected="updateSelected"
                        :allowSelect="false"
                    />
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="2">
            <view-list v-on:update-view="updateView" :views="dashboardViews" :allow-edit="false" />
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import viewList from '../issues/issue/views';
import issuesGroups from '../issues/issue/groups';
import issuesView from '../issues/issue/view';

export default {
    name: 'dashboard',
    data: () => ({
        loading: false,
        displayGroup: true,
        view: undefined,
        filters: [],
        group: undefined,
        selected: [],
    }),
    components: { viewList, issuesGroups, issuesView },
    created() {
        this.fetchProjects();
    },
    watch: {
        profile: function () {
            this.fetchProjects();
        },
        dashboardViews: function (value) {
            if (value.length === 0) {
                this.$router.push({
                    name: 'projects',
                });
            }
        },
    },
    computed: {
        ...mapGetters(['profile', 'dashboardViews', 'projects']),
    },
    methods: {
        ...mapActions(['findViewsFromProfiles', 'searchProjects', 'findUserLabels']),
        async fetchProjects() {
            this.loading = true;

            // find views
            await this.findViewsFromProfiles();

            const query = {
                cols: ['members'],
            };

            // find projects
            const projects = await this.searchProjects(query);

            const users = [
                ...new Set(
                    projects
                        .map((p) => p.members)
                        .reduce((a, b) => a.concat(b), [])
                        .map((m) => m.user),
                ),
            ];

            // find user labels
            await this.findUserLabels(users);
            this.loading = false;
        },
        updateView(view) {
            this.view = view;
        },
        updateGroup(group) {
            this.group = group;
        },
        updateSelected(selected) {
            this.selected = selected;
        },
    },
};
</script>
