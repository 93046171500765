<template>
    <v-card v-if="this.views">
        <v-card-text>
            <v-list dense nav>
                <v-list-item v-if="allowCreate">
                    <v-list-item-content>
                        <v-btn color="teal darken-3" :outlined="!$vuetify.theme.dark" small @click="createView">
                            <span v-text="$t('views.actions.add')" />
                        </v-btn>
                    </v-list-item-content>
                </v-list-item>

                <template v-for="(group, j) in groups">
                    <v-subheader :key="'h' + j" class="text-uppercase">{{ group }}</v-subheader>
                    <v-list-item-group :key="'g' + j" :value="view" color="primary">
                        <v-list-item v-for="v in findViewsFromGroup(group)" :key="v.id" :value="v" @click="showView(v)">
                            <v-list-item-content>
                                <v-list-item-title v-text="v.label" />
                            </v-list-item-content>
                            <v-btn v-if="allowEditing(v)" icon @click="editView(v)">
                                <v-icon color="grey lighten-1">mdi-playlist-edit</v-icon>
                            </v-btn>
                        </v-list-item>
                    </v-list-item-group>
                </template>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrderBy from 'lodash/orderBy';

export default {
    name: 'project-issues-views',
    data: () => ({
        view: undefined,
    }),
    components: {},
    mounted() {
        this.initView();
    },
    watch: {
        views: function () {
            this.initView();
        },
    },
    props: {
        views: {
            required: true,
        },
        allowCreate: {
            default: false,
        },
        allowEdit: {
            default: true,
        },
        createRoute: {
            default: 'project-view-create',
        },
        editRoute: {
            default: 'project-view-edit',
        },
    },
    computed: {
        ...mapGetters(['project', 'profile']),
        sessionVariable() {
            return this.$route.path + '#views';
        },
        groups() {
            return Array.from(new Set(this.displayingViews.map((p) => p.group)));
        },
        viewId() {
            return this.view ? this.view.id : undefined;
        },
        displayingViews() {
            return OrderBy(this.views, ['group', 'name'], ['asc', 'asc']);
        },
    },
    methods: {
        initView() {
            const id = window.sessionStorage.getItem(this.sessionVariable);
            if (id) {
                const view = this.views.find((v) => v.id === id);
                this.showView(view ? view : this.views[0]);
            } else {
                this.showView(this.views[0]);
            }
        },
        findViewsFromGroup(group) {
            return this.displayingViews.filter((v) => v.group === group);
        },
        createView() {
            this.$router.push({ name: this.createRoute });
        },
        allowEditing(v) {
            return this.allowEdit && this.view && this.view.id === v.id && this.view.createdBy === this.profile.name;
        },
        editView(view) {
            this.$router.push({
                name: this.editRoute,
                params: {
                    view_id: view.id,
                },
            });
        },
        showView(view) {
            this.view = view;
            if (view && view.id) {
                window.sessionStorage.setItem(this.sessionVariable, view.id);
            }
            this.$emit('update-view', view);
        },
    },
};
</script>

<style></style>
